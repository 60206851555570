import axios from "axios";
import BaseEntityModel from "./../general/BaseEntityModel";
import Brand from "./../brands/Brand";
import BrandServicesFilter from "./BrandServicesFilter";
import BrandServiceImg from "@/assets/images/brand-services.svg";
import { PAGE_SIZE, STATUS, BASE_URL } from "@/utils/constants";
import i18n from "@/i18n";
import store from "@/store";

export default class BrandService extends BaseEntityModel {
  defaultImg = BrandServiceImg;
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue() {
    this.brandServiceToken = "";
    this.brandServicePriceTypeToken = "";
    this.brandServicePriceTypeNameCurrent = "";
    this.brandServicePriceTypeNameEn = "";
    this.brandServicePriceTypeNameAr = "";
    this.brandServicePriceTypeNameUnd = "";
    this.brandServicePriceValue = "";
    this.brandServiceNameCurrent = "";
    this.brandServiceNameEn = "";
    this.brandServiceNameAr = "";
    this.brandServiceNameUnd = "";
    this.brandServiceDescriptionCurrent = "";
    this.brandServiceDescriptionAr = "";
    this.brandServiceDescriptionEn = "";
    this.brandServiceDescriptionUnd = "";
    this.brandServiceImagePath = "";
    this.brandServiceNotes = "";
    this.brandServiceArchiveStatus = "";
    this.brandToken = "";
    this.brandData = new Brand();
  }
  fillData(data) {
    if (data) {
      this.fillDataBaseEntityModelBaseEntityModel(data);
      this.brandServiceToken = data.brandServiceToken;
      this.brandServicePriceTypeToken = data.brandServicePriceTypeToken;
      this.brandServicePriceTypeNameCurrent =
        data.brandServicePriceTypeNameCurrent;
      this.brandServicePriceTypeNameEn = data.brandServicePriceTypeNameEn;
      this.brandServicePriceTypeNameAr = data.brandServicePriceTypeNameAr;
      this.brandServicePriceTypeNameUnd = data.brandServicePriceTypeNameUnd;
      this.brandServicePriceValue = data.brandServicePriceValue;
      this.brandServiceNameCurrent = data.brandServiceNameCurrent;
      this.brandServiceNameEn = data.brandServiceNameEn;
      this.brandServiceNameAr = data.brandServiceNameAr;
      this.brandServiceNameUnd = data.brandServiceNameUnd;
      this.brandServiceDescriptionCurrent = data.brandServiceDescriptionCurrent;
      this.brandServiceDescriptionAr = data.brandServiceDescriptionAr;
      this.brandServiceDescriptionEn = data.brandServiceDescriptionEn;
      this.brandServiceDescriptionUnd = data.brandServiceDescriptionUnd;
      this.brandServiceImagePath = data.brandServiceImagePath;
      this.brandServiceNotes = data.brandServiceNotes;
      this.brandServiceArchiveStatus = data.brandServiceArchiveStatus;
      this.brandToken = data.brandToken;
      this.brandData.fillData(data.brandData);
    }
  }

  async getAllBrandServices(
    language,
    userAuthorizeToken,
    pagination,
    filterData
  ) {
    return await axios.get(
      `/api/BrandServices/GetAllBrandServices?language=${language}&userAuthorizeToken=${userAuthorizeToken}&paginationStatus=true&pageSize=${PAGE_SIZE}&page=${pagination.selfPage}&filterStatus=true&activityTypeToken=${filterData.activityTypeToken}&brandToken=${filterData.brandToken}&brandServicePriceTypeToken=${filterData.brandServicePriceTypeToken}&textSearch=${filterData.textSearch}`
    );
  }

  async getBrandServiceDialog(
    language,
    userAuthorizeToken,
    filterData = new BrandServicesFilter()
  ) {
    let options = [];
    try {
      let response = await axios.get(
        `/api/BrandServices/GetBrandServiceDialog?language=${language}&userAuthorizeToken=${userAuthorizeToken}&filterStatus=true&activityTypeToken=${filterData.activityTypeToken}&brandToken=${filterData.brandToken}&brandServicePriceTypeToken=${filterData.brandServicePriceTypeToken}&textSearch=${filterData.textSearch}`
      );
      const itemsData = response.data.itemsData;
      if (response.data.status == STATUS.SUCCESS) {
        for (let item in itemsData) {
          options.push({
            value: itemsData[item]["itemToken"],
            text: itemsData[item]["itemName"],
            image: itemsData[item]["itemImagePath"]
              ? BASE_URL + itemsData[item]["itemImagePath"]
              : BrandServiceImg,
          });
        }
      } else if (response.data.status == STATUS.INVALID_TOKEN) {
        store.dispatch("logoutUser");
      } else {
        options.push({
          value: "",
          text: response.data.msg,
          image: BrandServiceImg,
        });
      }
    } catch (error) {
      options.push({
        value: "",
        text: i18n.t("errorCatch"),
        image: BrandServiceImg,
      });
    }
    return options;
  }

  async getBrandServiceDetails(language, userAuthorizeToken) {
    return await axios.get(
      `/api/BrandServices/GetBrandServiceDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&token=${this.brandServiceToken}`
    );
  }

  async addOrUpdateBrandService(language, userAuthorizeToken) {
    var formData = new FormData();
    formData.append("mediaFile", this.mediaFile);
    formData.append("language", language);
    formData.append("userAuthorizeToken", userAuthorizeToken);
    formData.append("brandServiceToken", this.brandServiceToken);
    formData.append("brandToken", this.brandToken);
    formData.append("fullCode", this.fullCode);
    formData.append(
      "brandServicePriceTypeToken",
      this.brandServicePriceTypeToken
    );
    formData.append("brandServicePriceValue", this.brandServicePriceValue);
    formData.append("brandServiceNameEn", this.brandServiceNameEn);
    formData.append("brandServiceNameAr", this.brandServiceNameAr);

    formData.append(
      "brandServiceDescriptionAr",
      this.brandServiceDescriptionAr
    );
    formData.append(
      "brandServiceDescriptionEn",
      this.brandServiceDescriptionEn
    );

    formData.append("brandServiceNotes", this.brandServiceNotes);

    if (this.brandServiceToken == "" || this.brandServiceToken == undefined) {
      return await axios.post(`/api/BrandServices/AddBrandService`, formData);
    } else {
      return await axios.post(
        `/api/BrandServices/UpdateBrandService`,
        formData
      );
    }
  }

  async archiveBrandService(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      token: this.brandServiceToken,
      archiveStatus: true,
    };

    return await axios.post(`/api/BrandServices/ArchiveBrandService`, data);
  }
}
