<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form autocomplete="off">
      <div class="row">
        <div
          class="imgView"
          :style="{ 'background-image': `url(${imageSrc})` }"
          alt="Image"
          @click="selectImage"
        ></div>
        <input
          ref="fileInput"
          type="file"
          v-on:change="pickFile"
          class="d-none"
          accept=".jpg, .jpeg, .png"
        />
      </div>

      <div class="my-card">
        <div class="row">
          <CustomSelectBoxWithImg
            :className="'col-md-6'"
            :id="'activityTypeToken'"
            :value="brandService.brandData.activityTypeToken"
            :options="activityTypeTokenOptions"
            v-on:changeValue="activityTypeChanged($event)"
            :title="$t('activityTypes.select')"
            :imgName="'activity-types.svg'"
          />
          <CustomSelectBoxWithImg
            :className="'col-md-6'"
            :id="'brandToken'"
            :value="brandService.brandToken"
            :options="brandTokenOptions"
            v-on:changeValue="brandService.brandToken = $event"
            :title="$t('brands.select')"
            :imgName="'brand.svg'"
          />

          <CustomInput
            :className="'col-md-12'"
            :id="'fullCode'"
            :value="brandService.fullCode"
            v-on:changeValue="brandService.fullCode = $event"
            :title="$t('code')"
            :imgName="'code.svg'"
          />

          <CustomInput
            :className="'col-md-6'"
            :id="'brandServiceNameAr'"
            :value="brandService.brandServiceNameAr"
            v-on:changeValue="brandService.brandServiceNameAr = $event"
            :title="$t('brandServices.nameAr')"
            :imgName="'brand-services.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'brandServiceNameEn'"
            :value="brandService.brandServiceNameEn"
            v-on:changeValue="brandService.brandServiceNameEn = $event"
            :title="$t('brandServices.nameEn')"
            :imgName="'brand-services.svg'"
          />
          <CustomSelectBoxWithImg
            :className="'col-md-6'"
            :id="'brandServicePriceTypeToken'"
            :value="brandService.brandServicePriceTypeToken"
            :options="brandServicePriceTypeTokenOptions"
            v-on:changeValue="brandService.brandServicePriceTypeToken = $event"
            :title="$t('selectPriceType')"
            :imgName="'type.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'brandServicePriceValue'"
            :value="brandService.brandServicePriceValue"
            v-on:changeValue="brandService.brandServicePriceValue = $event"
            :title="$t('brandServices.price')"
            :imgName="'money.svg'"
          />

          <TextArea
            :className="'col-md-6'"
            :id="'brandServiceDescriptionAr'"
            :value="brandService.brandServiceDescriptionAr"
            v-on:changeValue="brandService.brandServiceDescriptionAr = $event"
            :title="$t('brandServices.descriptionAr')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'brandServiceDescriptionEn'"
            :value="brandService.brandServiceDescriptionEn"
            v-on:changeValue="brandService.brandServiceDescriptionEn = $event"
            :title="$t('brandServices.descriptionEn')"
            :imgName="'description.svg'"
          />

          <TextArea
            :className="'col-md-12'"
            :id="'brandServiceNotes'"
            :value="brandService.brandServiceNotes"
            v-on:changeValue="brandService.brandServiceNotes = $event"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
        </div>
      </div>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="addOrUpdateBrandService"
        >
          {{ submitName }}
        </button>
        <router-link :to="{ name: 'BrandServices' }" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { BASE_URL } from "@/utils/constants";
import ActivityType from "@/models/activityTypes/ActivityType";
import Brand from "@/models/brands/Brand";
import BrandsFilter from "@/models/brands/BrandsFilter";
import CustomInput from "@/components/general/CustomInput.vue";
import CustomSelectBoxWithImg from "@/components/general/CustomSelectBoxWithImg.vue";
import TextArea from "@/components/general/TextArea.vue";
import createToastMixin from "@/utils/create-toast-mixin";
import { setDataMultiLang } from "@/utils/functions";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomInput,
    CustomSelectBoxWithImg,

    TextArea,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken", "constantsListsData"]),
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      imageSrc: "",
      activityType: new ActivityType(),
      activityTypeTokenOptions: [],
      brand: new Brand(),
      brandsFilter: new BrandsFilter(),
      brandTokenOptions: [],
      brandServicePriceTypeTokenOptions: [],
    };
  },
  props: ["brandService", "submitName"],
  methods: {
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
        this.brandService.mediaFile = file[0];
      }
    },

    async getDialogOfActivityTypes() {
      this.isLoading = true;
      try {
        this.activityTypeTokenOptions =
          await this.activityType.getDialogOfActivityTypes(
            this.language,
            this.userAuthorizeToken
          );
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    activityTypeChanged(activityTypeToken) {
      this.brandService.brandData.activityTypeToken = activityTypeToken;
      // this.brandService.brandToken = "";
      this.brandsFilter.activityTypeToken = activityTypeToken;
      this.getBrandDialog();
    },
    async getBrandDialog() {
      this.isLoading = true;

      try {
        this.brandTokenOptions = await this.brand.getBrandDialog(
          this.language,
          this.userAuthorizeToken,
          this.brandsFilter
        );
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfPriceTypes() {
      this.isLoading = true;
      this.brandServicePriceTypeTokenOptions = [];
      this.brandServicePriceTypeTokenOptions.push({
        value: "",
        text: this.$t("selectPriceType"),
      });
      let priceTypes = this.constantsListsData.listPriceType;
      for (let item in priceTypes) {
        this.brandServicePriceTypeTokenOptions.push({
          value: priceTypes[item]["itemToken"],
          text: setDataMultiLang(
            this.language,
            priceTypes[item]["itemNameAr"],
            priceTypes[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    async addOrUpdateBrandService() {
      this.$emit("addOrUpdateBrandService");
    },
  },
  async created() {
    this.imageSrc = this.brandService.brandServiceImagePath
      ? BASE_URL + this.brandService.brandServiceImagePath
      : this.brandService.defaultImg;

    this.getDialogOfActivityTypes();
    this.getBrandDialog();
    this.getDialogOfPriceTypes();
  },
};
</script>

<style lang="scss"></style>
